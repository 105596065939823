.navbar {
  background-color: white;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
}

.navbar div {
  width: calc(100% - 32px);
}

@media (min-width: 992px) {
  .menu {
    display: none;
  }

  .navbar div {
    width: 100%;
  }
}

.logo {
  height: 32px;
  margin-left: 1rem;
}

.spec-div {
  min-width: 200px;
  width: auto;
}

h4.ant-list-item-meta-title {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-list-item-meta-description>span {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}